import { EcAttributes } from "~/models/ec-attribute";
import { AnalysisLayerAPI, ZoneAPI } from "@ai360/core";
import { IAnalysisLayer } from "@ai360/core";

export const ANALYSIS_INFO_NAME_FOUR_MATION = "4Mation";
export const ANALYSIS_INFO_NAME_IMAGERY_SETUP = "Imagery Setup - Sentinel 2";
export const ANALYSIS_INFO_NAME_MANAGEMENT_AREA = "Management Area";
export const ANALYSIS_INFO_NAME_NORMALIZED_YIELD = "Normalized Yield";
export const ANALYSIS_INFO_NAME_PROFIT_LOSS = "Profit/Loss";
export const ANALYSIS_INFO_NAME_SEED_STRONG = "SeedStrong";
export const ANALYSIS_INFO_NAME_SEED_STRONG_DH = "SeedStrong DH";
export const ANALYSIS_INFO_NAME_EC_DATA = "Multi-Field EC Data";
export const ANALYSIS_INFO_TYPE_GUID = "analysisLayerTypeGuid";
export const ANALYSIS_INFO_TYPE_NAME = "name";
export const ANALYSIS_INFO_NAME_UNKNOWN = "UNKNOWN";
export const ANALYSIS_ERROR_CODES_LIST = [2800, 2806, 2836, 2835, 2903, 2911, 3008, 3009];

export interface IAnalysisLayerArea extends ZoneAPI.IZone {
    analysisLayerAreaGuid: string;
    analysisLayerAreaId: number;
    analysisLayerGuid: string;
    fieldBoundaryGuid: string;
    applyAnalysisToArea: boolean;
    calculatedArea: number;
}

export interface IAnalysisLayerSummary {
    analysisLayerAreaList: AnalysisLayerAPI.IAnalysisLayerZone[];
    batchGuid: string;
    isNew: boolean;
    layers: IAnalysisLayer[];
    name: string;
    numClasses: string;
    typeGuid: string;
}

export interface IAnalysisLayerSummaryItem {
    analysisLayerGuid: string;
    analysisLayerTypeGuid: string;
    analysisLayerTypeName: string;
    batchGuid: string;
    fieldBoundaryGuid: string;
    fieldGuid: string;
    name: string;
    numClasses: number;
    status: string;
}

export interface IAnalaysisManagementArea extends IAnalysisLayerSummary {
    croppingSeasonGuid: string;
    isMultipartZoneAutoSplitAllowed: () => boolean;
    type: typeof ANALYSIS_INFO_NAME_MANAGEMENT_AREA;
    managementAreaTemplateGuid: string;
    managementAreaData: Map<number, ManagementAreaAttribute[]>;
}

export interface IAnalysisMultiFieldEcDataSummary extends IAnalysisLayerSummary {
    type: typeof ANALYSIS_INFO_NAME_EC_DATA;
    classificationMethod: string;
    attribute: number;
    colorRampGuid: string;
}

export interface IAnalysisNormaliedYieldSummary extends IAnalysisLayerSummary {
    type: typeof ANALYSIS_INFO_NAME_NORMALIZED_YIELD;
    agEventGeneralGuidList: string[];
    isMultipartZoneAutoSplitAllowed: () => boolean;
}

export interface IAnalysisImagerySetupSummary extends IAnalysisLayerSummary {
    agEventGeneralGuidList: string[];
    analysisLayerGuid: string;
    analysisLayerType: typeof ANALYSIS_INFO_NAME_IMAGERY_SETUP;
    analysisLayerTypeGuid: string;
    calculateAbsoluteNdvi: boolean;
    croppingSeasonGuid: string;
    endMonth: number;
    hasChanged: boolean;
    imageryTypeGuidList: string[];
    maximumPercentCloudsAllowed: number;
    name: string;
    startMonth: number;
    type: typeof ANALYSIS_INFO_NAME_IMAGERY_SETUP;
    userGuid: string;
}

export interface IAnalysisProfitLossSummary extends IAnalysisLayerSummary {
    type: typeof ANALYSIS_INFO_NAME_PROFIT_LOSS;
    cropGuid: string;
    sellingPrice: number;
    cost: ProfitLossCost;
    croppingSeasonGuid: string;
    detailedTypeCostInfo?: ProfitLossDetailedCostInfo;
    agEventGeneralGuidList: string[];
    eventSelectionUsed: boolean;
}

export interface IAnalysisSeedStrongSummary extends IAnalysisLayerSummary {
    type:
        | typeof ANALYSIS_INFO_NAME_SEED_STRONG
        | typeof ANALYSIS_INFO_NAME_SEED_STRONG_DH
        | typeof ANALYSIS_INFO_NAME_FOUR_MATION;
    normalizedYieldGuid: string;
    useEcData: boolean;
    isSeedStrongDH: boolean;
    is4Mation: boolean;
}

export enum ProfitLossCostType {
    CostPerAcre = 0,
    BreakEvenYield = 1,
    Detailed = 2,
}

export interface ProfitLossCost {
    type: ProfitLossCostType;
    value?: number;
}

export interface ProfitLossActualCost {
    croppingSeasonGuid: string;
    fieldGuids: string[];
}

export interface ProfitLossDetailedCostInfo {
    seed: number;
    fertilizer: number;
    chemical: number;
    irrigation: number;
    harvest: number;
    equipment: number;
    labor: number;
    cropInsurance: number;
    interestOperatingCapital: number;
    miscellaneous: number;
    land: number;
    nonCropIncome: number;
    total: number;
    useSeed: boolean;
    useFertilizer: boolean;
    useChemical: boolean;
}

export interface ManagementAreaAttribute {
    importAttributeGuid: string;
    columnOrder: number;
    dataType: string;
    name: string;
    textValue?: string;
    yesNoValue?: boolean;
    decimalIntValue?: number;
}

export type AnyAnalysisSummary =
    | IAnalaysisManagementArea
    | IAnalysisMultiFieldEcDataSummary
    | IAnalysisNormaliedYieldSummary
    | IAnalysisProfitLossSummary
    | IAnalysisSeedStrongSummary
    | IAnalysisImagerySetupSummary;

export const areAllRequiredFieldsSet = (summary: AnyAnalysisSummary): boolean => {
    switch (summary.type) {
        case ANALYSIS_INFO_NAME_NORMALIZED_YIELD:
            return (
                summary.agEventGeneralGuidList &&
                summary.agEventGeneralGuidList.length > 0 &&
                summary.layers.length > 0 &&
                summary.name !== ""
            );
        case ANALYSIS_INFO_NAME_SEED_STRONG:
        case ANALYSIS_INFO_NAME_SEED_STRONG_DH:
        case ANALYSIS_INFO_NAME_FOUR_MATION:
            return (
                summary.normalizedYieldGuid !== "" &&
                summary.layers.length > 0 &&
                summary.name !== ""
            );
        case ANALYSIS_INFO_NAME_PROFIT_LOSS:
            return (
                summary.layers.length > 0 &&
                summary.name != null &&
                summary.name !== "" &&
                summary.croppingSeasonGuid != null &&
                summary.croppingSeasonGuid !== "" &&
                summary.cropGuid != null &&
                summary.cropGuid !== "" &&
                summary.sellingPrice != null &&
                summary.sellingPrice > 0 &&
                summary.cost.value != null &&
                summary.cost.value > 0
            );
        case ANALYSIS_INFO_NAME_EC_DATA:
            return (
                summary.layers.length > 0 &&
                summary.name !== "" &&
                EcAttributes.all()
                    .map((x) => x.attributeId)
                    .includes(summary.attribute) &&
                summary.numClasses != null &&
                summary.colorRampGuid != null &&
                summary.classificationMethod != null
            );
        case ANALYSIS_INFO_NAME_MANAGEMENT_AREA:
            return (
                summary.layers.length > 0 &&
                summary.name !== "" &&
                Object.values(summary.managementAreaData)
                    .flat()
                    .some(
                        (a) =>
                            a.textValue != null ||
                            a.yesNoValue === true ||
                            a.decimalIntValue != null
                    )
            );
        case ANALYSIS_INFO_NAME_IMAGERY_SETUP:
            return (
                summary.name !== "" &&
                summary.croppingSeasonGuid != null &&
                summary.imageryTypeGuidList &&
                summary.imageryTypeGuidList.length > 0 &&
                summary.endMonth != null &&
                summary.startMonth != null &&
                summary.maximumPercentCloudsAllowed != null &&
                summary.maximumPercentCloudsAllowed < 101 &&
                summary.hasChanged
            );
    }
};
